<template>
    <v-container>
        <v-card v-if="form" class="rounded-lg" elevation="1">
            <!--     <v-card-title class="d-flex align-center">
                <v-icon color="primary" class="mr-2">mdi-form-select</v-icon>
                {{ form.title || 'Untitled Form' }}
            </v-card-title>

            <v-divider></v-divider> -->

            <v-card-text>
                <v-stepper v-if="form.sections.length > 1" v-model="currentStep">
                    <!-- Stepper Header -->
                    <v-stepper-header>
                        <template v-for="(section, index) in form.sections">
                            <v-stepper-step :key="`step-${index}`" :complete="currentStep > index + 1" :step="index + 1"
                                :rules="[() => !sectionErrors[index]]">
                                {{ section.title || `Section ${index + 1}` }}
                            </v-stepper-step>
                            <v-divider v-if="index < form.sections.length - 1" :key="`divider-${index}`"></v-divider>
                        </template>
                    </v-stepper-header>

                    <!-- Stepper Content -->
                    <v-stepper-items>
                        <v-stepper-content v-for="(section, index) in form.sections" :key="`content-${index}`"
                            :step="index + 1">
                            <section-runner :ref="`section_${index}`" :section="section" v-model="formData[section.id]"
                                :readonly="isReadonly"
                                @validation="handleSectionValidation(index, $event)"></section-runner>

                            <div class="d-flex mt-6">
                                <v-btn v-if="index > 0" text @click="currentStep--">
                                    <v-icon left>mdi-arrow-left</v-icon>
                                    Previous
                                </v-btn>
                                <v-spacer></v-spacer>
                                <v-btn v-if="index < form.sections.length - 1" color="primary"
                                    @click="isReadonly ? currentStep++ : validateAndProceed(index)">
                                    Next
                                    <v-icon right>mdi-arrow-right</v-icon>
                                </v-btn>
                                <v-btn v-else-if="!isReadonly" color="success" @click="submitForm"
                                    :loading="submitting">
                                    Submit
                                    <v-icon right>mdi-check</v-icon>
                                </v-btn>
                            </div>
                        </v-stepper-content>
                    </v-stepper-items>
                </v-stepper>

                <!-- Single Section Form -->
                <div v-else>
                    <section-runner ref="single_section" :section="form.sections[0]"
                        v-model="formData[form.sections[0].id]" :readonly="isReadonly"
                        @validation="handleSectionValidation(0, $event)"></section-runner>

                    <div v-if="!isReadonly" class="d-flex mt-6">
                        <v-spacer></v-spacer>
                        <v-btn color="success" @click="submitForm" :loading="submitting">
                            Submit
                            <v-icon right>mdi-check</v-icon>
                        </v-btn>
                    </div>
                </div>
            </v-card-text>
        </v-card>

        <!-- Loading State -->
        <v-card v-else-if="loading" class="rounded-lg" elevation="1">
            <v-card-text class="text-center pa-8">
                <v-progress-circular indeterminate color="primary"></v-progress-circular>
                <div class="mt-4">Loading form...</div>
            </v-card-text>
        </v-card>

        <!-- Error State -->
        <v-card v-else class="rounded-lg" elevation="1">
            <v-card-text class="text-center pa-8">
                <v-icon size="64" color="error">mdi-alert-circle</v-icon>
                <div class="text-h6 mt-4">Form Not Found</div>
                <div class="mt-2">The requested form could not be found.</div>
            </v-card-text>
        </v-card>
    </v-container>
</template>

<script>
import { getFirestore, doc, getDoc, collection, addDoc } from 'firebase/firestore';
import SectionRunner from './SectionRunner.vue';

export default {
    name: 'FormRunner',
    components: {
        SectionRunner
    },
    props: {
        formId: {
            type: String,
            required: true
        },
        evaluationId: {
            type: String,
            required: false,
            default: null
        },
        userId: {
            type: String,
            required: false,
            default: null
        },
        formInput: {
            type: Object,
            default: null
        }
    },
    data() {
        return {
            loading: true,
            form: null,
            currentStep: 1,
            formData: {},
            sectionErrors: {},
            submitting: false
        };
    },
    computed: {
        isReadonly() {
            return !!this.formInput;
        }
    },
    async created() {
        await this.fetchForm();
        if (this.formInput) {
            this.formData = this.formInput.data;
        }
    },
    methods: {
        resetForm() {
            this.formData = {};
            this.sectionErrors = {};
            this.currentStep = 1;
        },

        async fetchForm() {
            try {
                const db = getFirestore();
                const formDoc = await getDoc(doc(db, 'forms', this.formId));

                if (formDoc.exists()) {
                    this.form = { id: formDoc.id, ...formDoc.data() };
                    // Initialize formData with section IDs
                    this.form.sections.forEach(section => {
                        this.formData[section.id] = {};
                        this.sectionErrors[section.id] = false;
                    });
                }
            } catch (error) {
                console.error('Error fetching form:', error);
            } finally {
                this.loading = false;
            }
        },
        handleSectionValidation(sectionIndex, isValid) {
            this.$set(this.sectionErrors, sectionIndex, !isValid);
        },
        async validateAndProceed(index) {
            if (this.isReadonly) {
                this.currentStep++;
                return;
            }

            const sectionRef = this.$refs[`section_${index}`];
            if (sectionRef && sectionRef[0] && sectionRef[0].validate && sectionRef[0].validate()) {
                this.currentStep++;
            }
        },
        async submitForm() {
            let isValid = true;

            if (this.form.sections.length > 1) {
                const sectionRef = this.$refs[`section_${this.currentStep - 1}`];
                if (sectionRef && sectionRef[0]) {
                    isValid = sectionRef[0].validate();
                }
            } else {
                const sectionRef = this.$refs.single_section;
                if (sectionRef) {
                    isValid = sectionRef.validate();
                }
            }

            if (!isValid) {
                return;
            }

            this.submitting = true;
            try {
                let user_id = this.$store.state.Auth.token.claims.user_id;

                if (this.$props.userId) {
                    user_id = this.$props.userId;
                }

                const db = getFirestore();
                const formInput = await addDoc(collection(db, 'forms_inputs'), {
                    formId: this.form.id,
                    date: new Date(),
                    data: this.formData,
                    user_id: user_id,
                    evaluationId: this.evaluationId
                });

                // Emit with the form input ID and reference
                this.$emit('submitted', {
                    formInputId: formInput.id,
                    evaluatorId: user_id,
                    date: new Date(),
                    evaluationId: this.evaluationId
                });
            } catch (error) {
                console.error('Error submitting form:', error);
            } finally {
                this.submitting = false;
            }
        }
    }
};
</script>

<style scoped>
.v-stepper {
    box-shadow: none !important;
}
</style>