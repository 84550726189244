<template>
    <div class="section-runner">
        <h3 class="text-h6 mb-4">
            <v-icon color="primary" class="mr-2">mdi-form-select</v-icon>
            {{ section.title }}
        </h3>
        <p v-if="section.description" class="mb-6">{{ section.description }}</p>

        <v-form ref="form" v-model="isValid" lazy-validation>
            <v-card v-for="question in section.questions" :key="question.id" outlined class="mb-4">
                <v-card-text>
                    <div class="d-flex align-center mb-2">
                        <div class="text-subtitle-1">
                            {{ question.text }}
                            <span v-if="question.required" class="red--text">*</span>
                        </div>
                    </div>

                    <component :is="getQuestionRunComponent(question.type)" :ref="`question_${question.id}`"
                        :question="question" v-model="localValue[question.id]"
                        :rules="question.required ? [v => !!v || 'Este campo es requerido'] : []" :readonly="readonly"
                        :error-messages="question.required && !isQuestionAnswered(question) ? ['Este campo es requerido'] : []"
                        mode="run">
                    </component>
                </v-card-text>
            </v-card>
        </v-form>
    </div>
</template>

<script>
import ShortAnswerQuestionRun from './questions/ShortAnswerQuestionRun.vue';
import LongAnswerQuestionRun from './questions/LongAnswerQuestionRun.vue';
import MultipleChoiceQuestionRun from './questions/MultipleChoiceQuestionRun.vue';
import CheckboxQuestionRun from './questions/CheckboxQuestionRun.vue';
import DateQuestionRun from './questions/DateQuestionRun.vue';
import TimeQuestionRun from './questions/TimeQuestionRun.vue';
import RatingQuestionRun from './questions/RatingQuestionRun.vue';
import SliderQuestionRun from './questions/SliderQuestionRun.vue';

export default {
    name: 'SectionRunner',
    components: {
        ShortAnswerQuestionRun,
        LongAnswerQuestionRun,
        MultipleChoiceQuestionRun,
        CheckboxQuestionRun,
        DateQuestionRun,
        TimeQuestionRun,
        RatingQuestionRun,
        SliderQuestionRun
    },
    props: {
        section: {
            type: Object,
            required: true
        },
        value: {
            type: Object,
            default: () => ({})
        },
        readonly: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            localValue: {},
            isValid: true
        };
    },
    created() {
        // Initialize localValue with existing values or empty values for each question
        this.section.questions.forEach(question => {
            this.$set(this.localValue, question.id, this.value[question.id] || null);
        });
    },
    methods: {
        getQuestionRunComponent(type) {
            const componentMap = {
                short_answer: 'ShortAnswerQuestionRun',
                long_answer: 'LongAnswerQuestionRun',
                multiple_choice: 'MultipleChoiceQuestionRun',
                checkbox: 'CheckboxQuestionRun',
                date: 'DateQuestionRun',
                time: 'TimeQuestionRun',
                rating: 'RatingQuestionRun',
                slider: 'SliderQuestionRun'
            };
            return componentMap[type] || 'ShortAnswerQuestionRun';
        },
        validate() {
            if (!this.$refs.form) {
                return false;
            }

            let formValid = this.$refs.form.validate();
            let questionsValid = true;

            // Validate each question component
            this.section.questions.forEach(question => {
                const questionRef = this.$refs[`question_${question.id}`];
                if (questionRef && questionRef[0] && questionRef[0].validate) {
                    const questionValid = questionRef[0].validate();
                    questionsValid = questionsValid && questionValid;
                } else if (question.required) {
                    // If question is required but has no specific validation
                    const value = this.localValue[question.id];
                    const isValid = value !== null && value !== undefined && value !== '';
                    questionsValid = questionsValid && isValid;
                }
            });

            return formValid && questionsValid;
        },
        reset() {
            this.$refs.form.reset();
        },
        resetValidation() {
            this.$refs.form.resetValidation();
        },
        isQuestionAnswered(question) {
            const value = this.localValue[question.id];
            if (Array.isArray(value)) {
                return value.length > 0;
            }
            return value !== null && value !== undefined && value !== '';
        }
    },
    watch: {
        isValid: {
            handler(newVal) {
                this.$emit('validation', newVal);
            }
        },
        localValue: {
            handler(newVal) {
                this.$emit('input', newVal);

                // Check validation status
                let isValid = true;
                this.section.questions.forEach(question => {
                    if (question.required && !this.isQuestionAnswered(question)) {
                        isValid = false;
                    }
                });

                if (this.isValid !== isValid) {
                    this.isValid = isValid;
                }
            },
            deep: true
        },
        value: {
            handler(newVal) {
                // Update localValue when external value changes
                Object.keys(newVal).forEach(key => {
                    this.$set(this.localValue, key, newVal[key]);
                });
            },
            deep: true
        }
    }
};
</script>