<template>
    <!-- :rules="rules" -->
    <v-text-field hide-details="" class="rounded-0" single-line :value="value" @input="$emit('input', $event)"
        :label="label" type="number" step="0.1" filled dense :suffix="unit" :readonly="readonly">
    </v-text-field>
</template>

<script>
export default {
    name: 'MeasurementField',
    props: {
        value: [String, Number],
        label: String,
        unit: String,
        icon: String,
        rules: Array,
        readonly: {
            type: Boolean,
            default: false
        }
    }
}
</script>